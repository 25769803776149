
import {
  UserOutlined,
  LockOutlined,
  SmileOutlined,
  MailOutlined
} from '@ant-design/icons-vue'
import { reactive, ref } from '@vue/reactivity'
import { REGIST } from '../api/api'
import { unref } from 'vue'
import type { UnwrapRef } from 'vue'
import type { RuleObject } from 'ant-design-vue/es/form'
import { useRouter } from 'vue-router'

interface registerFrom {
  username: string | null
  password: string | null
  confirmPassword: string | null
  nickname: string | null
  email: string | null
}
export default {
  components: {
    UserOutlined,
    LockOutlined,
    SmileOutlined,
    MailOutlined
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const router = useRouter()
    const registerFromRef = ref()
    const registerFrom: UnwrapRef<registerFrom> = reactive({
      username: '',
      password: '',
      confirmPassword: '',
      nickname: '',
      email: ''
    })
    const registerButtonLoading = ref(false)
    // 验证二次密码确认
    const checkConfirmPassword = async (_rule: RuleObject, value: string) => {
      if (value === '') {
        return Promise.reject(new Error('Please input Confirm password'))
      } else {
        if (value !== registerFrom.password) {
          return Promise.reject(
            new Error('The passwords entered twice do not match!')
          )
        }
        return Promise.resolve()
      }
    }
    // 注册表单验证规则
    const rules = {
      username: [
        {
          required: true,
          message: 'Please input Username',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: 'Please input Password',
          trigger: 'blur'
        }
      ],
      confirmPassword: [
        {
          required: true,
          validator: checkConfirmPassword,
          trigger: ['blur', 'change']
        }
      ],
      nickname: [
        {
          required: true,
          message: 'Please input Nickname',
          trigger: 'blur'
        }
      ],
      email: [
        {
          required: true,
          message: 'Please input Email',
          trigger: 'blur'
        }
      ]
    }
    // 注册接口
    async function register() {
      const form = unref(registerFromRef)
      if (!form) return
      await form.validate()
      registerButtonLoading.value = true
      await REGIST(registerFrom)
        .then((res) => {
          if (res.code === 0) {
            setTimeout(() => {
              router.push({
                name: 'Login'
              })
              registerButtonLoading.value = false
            }, 3000)
          } else {
            registerButtonLoading.value = false
          }
        })
        .catch((error) => {
          this.message.error(error.toString())
          registerButtonLoading.value = false
        })
    }

    // 跳转登录页面
    function toLogin() {
      router.push('/login')
    }

    return {
      registerFromRef,
      registerFrom,
      rules,
      register,
      registerButtonLoading,
      toLogin
    }
  }
}
